/* eslint-disable max-classes-per-file */
import hoistNonReactStatics from 'hoist-non-react-statics'
import { NextPage, NextPageContext } from 'next'
import { useRouter } from 'next/router'
import { destroyCookie, parseCookies } from '@upvestcz/common/cookies'
import * as R from 'ramda'
import React from 'react'
import {
    areRolesSufficient,
    Profile,
    RequiredRoles,
    normalizeProfile,
    destroyLoginCookies,
    setLoginCookies,
} from '@upvestcz/common/auth'
import { replace } from '@upvestcz/common/routing'
import { fetchAccount } from './api'
import { useAccount } from '../store/account'
import { useAuth } from '../store/auth'
import { useClearStore } from '../store'

export const usePermissionGuard = (requiredRoles: RequiredRoles) => {
    const [auth] = useAuth()

    if (!auth.loggedIn) {
        return false
    }

    return areRolesSufficient({ requiredRoles, profile: auth.profile })
}

export const requireAuth = (ProtectedComponent: TODO) => {
    const ComponentWithAuth: NextPage = props => <ProtectedComponent {...props} />

    // We want to hoist statics like `isNewDesign` or `getLayout`
    hoistNonReactStatics(ComponentWithAuth, ProtectedComponent)

    // It's important to put the `getInitialProps` static method
    // AFTER the `hoistNonReactStatics(...)` so that the implementation
    // below is not overridden by the `getInitialProps` static method
    // on the `ProtectedComponent` component
    ComponentWithAuth.getInitialProps = (ctx: NextPageContext) => {
        if (!(ctx as TODO).auth.loggedIn) {
            replace({
                ctx,
                location: '/login',
                cookies: {
                    redirect_url: ctx.asPath as string,
                },
                forwardParams: false,
            })

            return {}
        }

        if (typeof ProtectedComponent.getInitialProps === 'function') {
            const pageProps = ProtectedComponent.getInitialProps(ctx)

            return pageProps
        }

        return {}
    }

    return ComponentWithAuth
}

export const withPermissionCheck = (requiredRoles: RequiredRoles) => (ProtectedComponent: TODO) => {
    const WithPermissionCheck: NextPage = props => {
        return <ProtectedComponent {...props} />
    }

    // We want to hoist statics like `getLayout`
    hoistNonReactStatics(WithPermissionCheck, ProtectedComponent)

    WithPermissionCheck.getInitialProps = (ctx: NextPageContext) => {
        if (!(ctx as TODO).auth.loggedIn) {
            replace({ ctx, location: '/' })

            return {}
        }

        if (!areRolesSufficient({ requiredRoles, profile: (ctx as TODO).auth.profile })) {
            replace({ ctx, location: '/404' })

            return {}
        }

        if (typeof ProtectedComponent.getInitialProps === 'function') {
            const pageProps = ProtectedComponent.getInitialProps(ctx)

            return pageProps
        }

        return {}
    }

    return WithPermissionCheck
}

export function useLogout() {
    const router = useRouter()
    const clearStore = useClearStore()

    return async () => {
        destroyLoginCookies()

        const prevUrl = window.location.href
        const { route: prevRoute } = router // to allow logout from failed login
        await router.push('/login')

        // although we `await` the `route.push()`, if we put clearStore()
        // call right after it, it will be called before the route
        // actually changes and will cause an error on some pages
        // as the page will depend upon state which will be no longer
        // present (after clearStore())
        // it is not the nicest solution but I couldn't find anything regarding
        // this issue online
        const clearStoreInterval = setInterval(() => {
            if (prevUrl !== window.location.href || prevRoute === '/login') {
                clearStore()
                clearInterval(clearStoreInterval)
            }
        }, 100)
    }
}

export const useAfterAuth = () => {
    const [, dispatchAccount] = useAccount()
    const [, dispatchAuth] = useAuth()
    const router = useRouter()
    const cookies = parseCookies()
    const logout = useLogout()

    return async ({
        profile,
        token,
    }: {
        profile: Profile
        token: { id_token: string; access_token: string }
    }) => {
        const normalizedProfile = normalizeProfile(profile)
        setLoginCookies({
            id_token: token.id_token,
            access_token: token.access_token,
            profile: JSON.stringify(normalizedProfile),
        })

        try {
            const [account] = await (
                Promise.all([
                    fetchAccount(normalizedProfile.auth_id).catch(() => ({
                        data: null,
                    })),
                ]) as TODO
            ).then(R.map(R.prop('data')))

            await Promise.all([
                dispatchAccount({ type: 'set', payload: account }),
                dispatchAuth({
                    type: 'set',
                    payload: {
                        loggedIn: true,
                        profile: normalizedProfile,
                        token: token.id_token,
                    },
                }),
            ])

            if (cookies.redirect_url) {
                destroyCookie({}, 'redirect_url')
                router.push(cookies.redirect_url)
            } else {
                router.push('/')
            }
        } catch (err) {
            await logout() // abandon logging in because of error fetching critical info
            throw err
        }
    }
}
